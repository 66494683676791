/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./GoToVideo.css";

export const GoToVideo = () => {
  const [config, setConfig] = useState("");
  const latestVideos = extractVideos(config);

  const files = latestVideos.files || [];
  const filesLength = files.length;

  const hasError = "error" in latestVideos && config.length !== 0;

  return (
    <div className="go-to-video">
      <textarea
        onChange={({ target }) => {
          console.log(target.value);
          setConfig(target.value);
        }}
        value={config}
      />
      {filesLength > 0 && (
        <span className="quality">Video quality: {files[0].quality}</span>
      )}
      {hasError && <span className="error">{latestVideos.error}</span>}
      <a
        disabled={filesLength === 0}
        href={filesLength > 0 ? files[0].url : ""}
        download={filesLength > 0}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go To Video
      </a>
      {config.length > 0 && (
        <button className="clear" onClick={() => setConfig("")}>
          Clear
        </button>
      )}
    </div>
  );
};

function extractVideos(config) {
  const value = getValue(config.trim());
  const videos = getVideos(value);

  return videos;
}

function getVideos(value) {
  if (!value) {
    return { error: "No config value found." };
  }

  if (!value.request) {
    return { error: "No request in config." };
  }

  if (!value.request.files) {
    return { error: "No files in config." };
  }

  if (!value.request.files.progressive) {
    return { error: "No progressive files in config" };
  }

  const files = value.request.files.progressive;

  const sortedFiles = files.sort(
    ({ quality: a }, { quality: b }) => parseInt(b, 10) - parseInt(a, 10)
  );

  return { files: sortedFiles.map(({ quality, url }) => ({ quality, url })) };
}

function getValue(config) {
  if (config === "") {
    return;
  }

  if (config.startsWith("(function(")) {
    config = extractVar(config);
  }

  try {
    // eslint-disable-next-line no-eval
    return eval(`${makeSureAssignment(config, "config")}; config;`);
  } catch (_) {
    return;
  }
}

function extractVar(config) {
  const varConfig = config.indexOf("var config = ");
  let braceCount = 0;
  let i = config.indexOf("{", varConfig);

  while (true) {
    if (config.charAt(i) === "{") {
      braceCount++;
    }

    if (config.charAt(i) === "}") {
      braceCount--;
    }

    if (braceCount === 0) {
      break;
    }

    i++;
  }

  return config.substring(varConfig, i + 1);
}

function makeSureAssignment(script, variable) {
  if (
    script.startsWith(`var ${variable} =`) ||
    script.startsWith(`let ${variable} =`) ||
    script.startsWith(`const ${variable} =`)
  ) {
    return script;
  }

  return `var ${variable} = ${script}`;
}
